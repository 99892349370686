.Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fff;
  z-index: 99999;
}
.Loading svg,
.Loading i {
  position: absolute;
  top: 40%;
}
